/* This is overriding the default */
.MuiTabs-flexContainer {
    display: flex;
    flex-wrap: wrap;
}
a.Mui-selected {
  border-bottom: 1px solid #000;
}
.MuiTextField-root,
.MuiFilledInput-multiline {
  width: 100% !important;
  max-width: 600px;
}
.Mui-selected .MuiTab-wrapper {
  font-weight: 700;
}
.surveyLabel {
    font-size: 1.2rem;
    font-weight: 700;
  }