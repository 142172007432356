.diagrams-all {
    width: 100%;
    max-width: 600px;
    height: auto;
    max-height: 400px;
}
.reportTitle {
    font-size: 1.4rem;
    font-weight: 700;
}
.cardCSS {
    height: auto;
    min-height: 100vh;
}
.yesno-selection-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.yesno-select {
    flex: 2;
}
.yesno-select-address {
    margin-top: 20px;
    width: 100%;
}
.show-q17-checkbox {
    margin-top: 20px;
}
.show-q17-checkbox label input {
    margin-right: 10px;
}
.q17-box-wrapper {
    width: auto;
    overflow-y: scroll;
    max-height: 300px;
    padding: 10px;
    border: 1px double #CCC;
}
.q17-box {
    display: block;
    width: 100%;
    height: auto;
    max-width: 500px;
    border: 1px solid #494B5F;
    margin-bottom: 10px;
    padding: 5px;
}

/* sub navigation */
#sub-nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* Download button */
#download-graph-btn {
    cursor: pointer;
    font-size: 20px;
    border-radius: 4px;
    border: 0 none;
    padding: 5px 10px;
    margin-top: 20px;
    margin-right: 30px;
    background-color: #2196f3;
    color: #FFF;
}
#download-graph-btn:hover {
    background-color: #1866a7;
}